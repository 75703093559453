import React from 'react';
import { Link } from 'gatsby';

import Layout from 'components/Layout';
import Meta from 'components/Meta';
import 'styles/404.scss';
// import { Main } from 'styles/404';

const error404 = ({ location }) => (
  <Layout location={location}>
    <Meta title="Page not found 🤐 | ruttl " url="https://ruttl.com/404/" />
    <main className="styled-404-main">
      <div className="container">
        <div className="error-main">
          <img src="/assets/img/error.png" alt="error" />
          <h1>Error 404</h1>
          <p>
            The page you are looking for cannot be found. Please check the URL
            or click the button below to return to homepage
          </p>
          <Link to="/" className="button">
            Return to homepage
          </Link>
        </div>
      </div>
    </main>
  </Layout>
);

export default error404;
